@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');


h1 , h2 , h5 {
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    
    
}

h2 {
    padding-top: 10px;
    
}

h5 {
    text-align: justify;
    max-width: 50vw;
    color: rgb(14, 3, 71);
    border-left: 4px solid  #F9CD5E;
    padding-left: 8px;
    
}

.custom-certificates{

    /*background-color: #055160;*/
 background-color: #147171;
 flex-direction: column; /* using stack components */
 align-items: center;
 justify-content: center;
 font-size: calc(10px + 2vmin);
 color: white;
 z-index: 1;
 
 
}

.card-effect-certificate{
 margin: auto;
 transition: all 0.8s ease;
 border-radius: 0;
 position: relative;
}



.card-effect-certificate:hover {
 margin: auto;
 transition: all 0.8s ease;
 
 border-radius: 15px;
 background-color: #055160 !important ;

 img{
   transition: all 0.8s ease;
   border-radius: 15px;
   
 }
 
}

img{

 transition: opacity 0.8s ease; 
 opacity: 1; 
 border-radius: 0;
 
}

.card-video-certificate {
 opacity: 0;
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 156px;
 object-fit: cover;
 transition: opacity 0.8s ease-out, border-radius 0.8s ease-out; 
 position: absolute;
 z-index: 1;
 border-radius: 0;
 
}


.card-effect-certificate:hover .card-video {
 transition: all 0.8s ease;
 opacity: 1;
 border-radius: 75px;
 
}

.card-effect-certificate:hover img {
 transition: all 0.1s ease;
 opacity: 100;
}
.custom-service{

     /*background-color: #055160;*/
  background-color: #147171;
  
  flex-direction: column; /* using stack components */
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 1;
  
  
}

.card-effect{
  margin: auto;
  transition: all 0.8s ease;
  border-radius: 0;
  position: relative;
}



.card-effect:hover {
  margin: auto;
  transition: all 0.8s ease;
  border-radius: 75px;
  background-color: #055160 !important ;

  img{
    transition: all 0.8s ease;
    border-radius: 75px;
    
  }
  
}

img{

  transition: opacity 0.8s ease; 
  opacity: 1; 
  border-radius: 0;
  
}

.card-video {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 156px;
  object-fit: cover;
  transition: opacity 0.8s ease-out, border-radius 0.8s ease-out; 
  position: absolute;
  z-index: 1;
  border-radius: 0;
}


.card-effect:hover .card-video {
  transition: all 0.8s ease;
  opacity: 1;
  border-radius: 75px;
  
}

.card-effect:hover img {
  transition: all 0.1s ease;
  opacity: 0;
}
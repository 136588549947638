
*, html, body {
	margin:0;
	padding:0;
	}


.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #147171;
}

.App-header,
.App-navigation,
.App-main,
.App-footer {
  flex: 0 0 auto; /* Evita que os componentes se estiquem para ocupar todo o espaço disponível */
}

.App-header {
  /*background-color: #055160;*/
  background-color: #147171;
  /*min-height: 10vh;
  /*max-height: 20vh;*/
  

  flex-direction: column; /* using stack components */
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 2;
}

.App-main{
  background-color: #055160;
  /*background-color: #147171;*/
  min-height: 70vh;
    
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer{
  background-color: #147171;
  min-height: 11.5vh;
  max-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.custom-main{
  min-height: 65.3vh;
    
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.custom-bg{
  background-color: #055160;
}

.header-text{
  text-align: left;
}

span, .custom-color-font {
  color: #F9CD5E;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes lights {
  0%{
    background-position: 0}
  60%{
    background-position: 180px}
  100%{
    background-position: 240px}
}


.custom-txt-effects {
  
  
  transform: translate(-50%,-50%);
  
  color: #F9CD5E;
  background: linear-gradient(to right, #F9CD5E 0, rgb(255, 255, 255) 10%, #F9CD5E 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  animation: lights 3s infinite linear;
  animation-fill-mode: forwards;
  
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
}

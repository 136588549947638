@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,600');
.custom-effects {
  z-index: 2;

    .nav {
        font-family: 'Titillium Web';
        
        text-align: center;
        font-weight: 600;
      }
      
      .nav * {
        box-sizing: border-box;
        transition: all .35s ease;
      }
      
      .nav li {
        display: inline-block;
        list-style: outside none none;
        padding: 0;
      }
      
      .nav a {
        
        color: rgba(255,255,255,.5);
        position: relative;
        text-decoration: none;
        
      }
      
      .nav a::before,
      .nav a::after {
        content: '';
        height: 14px;
        width: 14px;
        position: absolute;
        transition: all .35s ease;
        opacity: 0;
      }
      
      .nav a::before {
        content: '';
        right: 0;
        top: 0;
        border-top: 3px solid  #ffee10;
        border-right: 3px solid #ffee10;
        transform: translate(-100%, 50%);
      }
      
      .nav a:after {
        content: '';
        left: 0;
        bottom: 0;
        border-bottom: 3px solid #ffee10;
        border-left: 3px solid #ffee10;
        transform: translate(100%, -50%)
      }
      
      .nav a:hover:before,
      .nav a:hover:after{
        transform: translate(0,0);
        opacity: 1;
      }
      
      .nav a:hover {
        color: #3DA35D;
      }
   
}
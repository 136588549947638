.App-main {
    position: relative;
    z-index: 1;
  }

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 22;
  }
  
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    
  }
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');


h1 , h2 , h5 {
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    
    
}

h2 {
    padding-top: 10px;
    
}

h5 {
    text-align: justify;
    max-width: 50vw;
    color: rgb(14, 3, 71);
    border-left: 4px solid  #F9CD5E;
    padding-left: 8px;
    
}

.custom-portifolio{

    /*background-color: #055160;*/
 background-color: #147171;
 flex-direction: column; /* using stack components */
 align-items: center;
 justify-content: center;
 font-size: calc(10px + 2vmin);
 color: white;
 z-index: 1;
 
 
}